<template>
  <div class="step-details">
    <h2>Step 1: Details</h2>
    <div class="form-group">
      <label for="businessSearch">Business</label>
      <input
        type="text"
        id="businessSearch"
        v-model="businessQuery"
        @input="searchBusiness"
        placeholder="Search for a business"
      />
      <ul v-if="businesses.length > 0" class="business-list">
        <li
          v-for="business in businesses"
          :key="business._id"
          @click="selectBusiness(business)"
        >
          {{ business.business_name }}
        </li>
      </ul>
    </div>
    <div v-if="giftCards.length > 0" class="form-group">
      <label for="giftCardSelect">Select Gift Card</label>
      <select id="giftCardSelect" v-model="selectedGiftCardId" @change="fetchGiftCardDetails">
        <option disabled value="">Select a gift card</option>
        <option v-for="giftCard in giftCards" :key="giftCard._id" :value="giftCard._id">
          {{ giftCard.dealtitle }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="dealTitle">Gift Card Title</label>
      <input
        type="text"
        id="dealTitle"
        v-model="dealTitle"
        @input="validateForm"
        required
      />
    </div>
    <div class="form-group">
      <label for="category">Category</label>
      <select id="category" v-model="category" @change="validateForm" required>
        <option disabled value="">Choose one</option>
        <option value="Restaurants">Restaurants</option>
        <option value="Beauty-spa">Beauty & Spa</option>
        <option value="Things-to-do">Things to do</option>
        <option value="Health-fitness">Health & Fitness</option>
        <option value="Automotive">Automotive</option>
        <option value="Home-services">Home Services</option>
        <option value="Retail">Retail</option>
        <option value="Technology">Technology</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div class="form-group">
      <label for="dealDetails">About Gift Card</label>
      <div id="dealDetails" ref="dealDetailsEditor"></div>
    </div>
    <div class="form-group">
      <label for="dealFinePrint">Fine Print</label>
      <div id="dealFinePrint" ref="dealFinePrintEditor"></div>
    </div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

export default {
  name: 'StepDetails',
  data() {
    return {
      dealTitle: '',
      category: '',
      dealDetails: '',
      dealFinePrint: '',
      businessQuery: '',
      businesses: [],
      giftCards: [],
      selectedGiftCardId: '',
      uploadedImages: [],
      quill: null,
      quillFinePrint: null,
      token: localStorage.getItem('token'),
    };
  },
  methods: {
    validateForm() {
      const isValid =
        this.dealTitle.trim().length > 0 &&
        this.category.trim().length > 0 &&
        (this.dealDetails ? this.quill.root.innerHTML.trim().length > 0 : false) &&
        (this.dealFinePrint ? this.quillFinePrint.root.innerHTML.trim().length > 0 : false);

      this.$emit('formValidation', isValid);
    },
    validateAndSave() {
      this.dealDetails = this.quill.root.innerHTML;
      this.dealFinePrint = this.quillFinePrint.root.innerHTML;
      if (this.dealTitle && this.category && this.dealDetails && this.dealFinePrint) {
        this.saveDetails();
        this.$emit('nextStep');
      }
    },
    saveDetails() {
      const giftCardDetails = {
        dealTitle: this.dealTitle,
        category: this.category,
        dealDetails: this.dealDetails,
        dealFinePrint: this.dealFinePrint,
      };
      localStorage.setItem('giftCardDetails', JSON.stringify(giftCardDetails));
    },
    setDetails(details) {
      this.dealTitle = details.dealTitle;
      this.category = details.category;
      this.dealDetails = details.dealDetails;
      this.dealFinePrint = details.dealFinePrint;
      this.quill.root.innerHTML = this.dealDetails;
      this.quillFinePrint.root.innerHTML = this.dealFinePrint;
    },
    async searchBusiness() {
      if (this.businessQuery.length > 2) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/search/business`, {
            params: { query: this.businessQuery },
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });
          this.businesses = response.data.businesses;
        } catch (error) {
          console.error('Error searching for businesses:', error);
        }
      } else {
        this.businesses = [];
      }
    },
    async selectBusiness(business) {
      localStorage.setItem('currentBusiness', business._id);
      this.businessQuery = business.business_name;
      this.businesses = [];
      await this.fetchGiftCards(business._id);
    },
    async fetchGiftCards(businessId) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/search/business/${businessId}/giftCards`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.giftCards = response.data.giftCards;
      } catch (error) {
        console.error('Error fetching gift cards:', error);
      }
    },
    async fetchGiftCardDetails() {
      if (this.selectedGiftCardId) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/search/giftCards/${this.selectedGiftCardId}`, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });
          const giftCard = response.data;
          const giftCardDetails = {
            dealTitle: giftCard.dealtitle,
            category: giftCard.category,
            dealDetails: giftCard.dealdetails,
            dealFinePrint: giftCard.dealfineprint,
          };

          const option = {
            currentPrice: giftCard.currentprice,
            dealName: giftCard.dealname,
            discount: giftCard.discount,
            exactDate: giftCard.exactdate,
            maxPerPurchase: giftCard.maxperpurchase,
            numberInStock: giftCard.numberinstock,
            releaseAmount: giftCard.releaseamount,
            releaseFrequency: giftCard.releasefrequency,
            giftCardCode: giftCard.giftcardcodes[0], // Pull the first gift card code
            businessCut: giftCard.businesscut, 
          };

          this.uploadedImages = giftCard.image_links || [];
          localStorage.setItem('giftCardDetails', JSON.stringify(giftCardDetails));
          localStorage.setItem('option', JSON.stringify(option));
          localStorage.setItem('generatedCodes', JSON.stringify(giftCard.giftcardcodes));
          localStorage.setItem('uploadedImagesGC', JSON.stringify(this.uploadedImages));
          localStorage.setItem('giftCardId', JSON.stringify(giftCard._id));

          this.setDetails(giftCardDetails);
        } catch (error) {
          console.error('Error fetching gift card details:', error);
        }
      }
    },
  },
  mounted() {
    this.quill = new Quill(this.$refs.dealDetailsEditor, {
      theme: 'snow',
      placeholder: 'Describe the deal...',
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'code-block', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    this.quillFinePrint = new Quill(this.$refs.dealFinePrintEditor, {
      theme: 'snow',
      placeholder: 'Add fine print...',
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'code-block', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    const savedDetails = JSON.parse(localStorage.getItem('giftCardDetails'));
    const savedImages = JSON.parse(localStorage.getItem('uploadedImagesGC'));
    if (savedDetails) {
      this.setDetails(savedDetails);
    }
    if (savedImages) {
      this.uploadedImages = savedImages;
    }

    this.$emit('formValidation', this.dealTitle && this.category && this.dealDetails);

    this.quill.on('text-change', () => {
      this.dealDetails = this.quill.root.innerHTML;
      this.validateForm();
    });

    this.quillFinePrint.on('text-change', () => {
      this.dealFinePrint = this.quillFinePrint.root.innerHTML;
      this.validateForm();
    });
  },
  watch: {
    dealTitle() {
      this.validateForm();
    },
    category() {
      this.validateForm();
    },
    dealDetails() {
      this.validateForm();
    },
    dealFinePrint() {
      this.validateForm();
    },
  },
};
</script>

<style scoped>
.step-details {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

#dealDetails,
#dealFinePrint {
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

#dealDetails:focus,
#dealFinePrint:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

.business-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
}

.business-list li {
  padding: 10px;
  cursor: pointer;
}

business-list li:hover {
  background-color: #f0f0f0;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .form-group {
    margin-bottom: 15px;
  }

  input,
  select,
  textarea {
    padding: 8px;
  }

  #dealDetails,
  #dealFinePrint {
    height: 180px;
  }

  .business-list li {
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .form-group {
    margin-bottom: 10px;
  }

  input,
  select,
  textarea {
    padding: 6px;
  }

  #dealDetails,
  #dealFinePrint {
    height: 160px;
  }

  .business-list li {
    padding: 6px;
  }
}

@media (max-width: 480px) {
  .form-group {
    margin-bottom: 5px;
  }

  input,
  select,
  textarea {
    padding: 5px;
  }

  #dealDetails,
  #dealFinePrint {
    height: 140px;
  }

  .business-list li {
    padding: 5px;
  }
}
</style>
