<template>
  <div id="app">
    <!-- Loading Spinner -->
    <LoadingSpinner v-if="loading" />

    <!-- Conditional rendering of NavBar or DashboardSidebar based on the current route -->
    <component :is="currentSidebar" :shoppingBagItems="shoppingBagItems" @update-shopping-bag="updateShoppingBag" v-show="!loading" />
    <router-view @add-to-shopping-bag="addToShoppingBag" v-show="!loading" />
    
    <!-- Conditional rendering of Footer based on the current route -->
    <Footer v-if="!isDashboardRoute && !loading" />
  </div>
</template>

<script>
import NavBar from './components/Frontend Nav Components/NavBar.vue';
import DashboardSidebar from './components/Dashboard Nav Components/DashboardSidebar.vue';
import Footer from './components/Frontend Nav Components/FooterBar.vue';
import LoadingSpinner from './components/LoadingSpinner.vue'; // Adjust the import path as needed

export default {
  name: 'App',
  components: {
    NavBar,
    DashboardSidebar,
    Footer,
    LoadingSpinner
  },
  computed: {
    // Computed property to determine which component to render based on the current route
    currentSidebar() {
      return this.isDashboardRoute ? DashboardSidebar : NavBar;
    },
    isDashboardRoute() {
      // Assuming dashboard paths start with '/dashboard'
      return this.$route.path.startsWith('/dashboard');
    }
  },
  data() {
    return {
      shoppingBagItems: JSON.parse(localStorage.getItem('shoppingBagItems')) || [],
      loading: true, // Initial loading state
    };
  },
  methods: {
    addToShoppingBag(item) {
      const shoppingBagItems = [...this.shoppingBagItems, item];
      this.shoppingBagItems = shoppingBagItems;
      localStorage.setItem('shoppingBagItems', JSON.stringify(shoppingBagItems));
    },
    updateShoppingBag(items) {
      this.shoppingBagItems = items;
      localStorage.setItem('shoppingBagItems', JSON.stringify(items));
    },
  },
  watch: {
    shoppingBagItems(newItems) {
      // Emitting an event upwards, which can be listened to by parent components
      this.$emit('update-shopping-bag', newItems);
    },
  },
  mounted() {
    // Simulate data loading
    setTimeout(() => {
      this.loading = false; // Set loading to false when data is ready
    }, 700); // Adjust the timeout as needed or replace with actual data loading logic
  }
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}
#app {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: 'Roboto', sans-serif;
}

.grecaptcha-badge { 
    visibility: hidden !important;
}
</style>
