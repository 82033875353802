<template>
  <div class="step-details">
    <h2>Step 1: Details</h2>
    <div class="form-group">
      <label for="dealTitle">Deal Title</label>
      <input type="text" id="dealTitle" v-model="dealtitle" @input="saveAndValidateForm" required />
    </div>

    <div class="form-group">
      <label for="expirationDate">Expiration Date</label>
      <input
        type="date"
        id="expirationDate"
        v-model="expirationdateInput" 
        @input="handleDateInput"
        required
      />
    </div>

    <div class="form-group">
      <label for="couponCode">Coupon Code</label>
      <div class="coupon-code-container">
        <input type="text" id="couponCode" v-model="couponcode" @input="saveAndValidateForm" required />
        <button type="button" @click="generateCouponCode">Generate</button>
      </div>
    </div>

    <div class="form-group">
      <label for="dealDetails">About Deal</label>
      <div id="dealDetails" ref="dealDetailsEditor"></div>
    </div>

    <div class="form-group">
      <label for="dealFinePrint">Fine Print</label>
      <div id="dealFinePrint" ref="dealFinePrintEditor"></div>
    </div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
  name: 'StepDetails',
  data() {
    return {
      dealtitle: '',
      expirationdate: '', // MM-DD-YYYY for saving
      expirationdateInput: '', // YYYY-MM-DD for the date input
      couponcode: '',
      dealdetails: '',
      dealfineprint: '',
      quill: null,
      quillFinePrint: null,
    };
  },
  methods: {
    // Handle date input in the date picker (YYYY-MM-DD format) and save it as MM-DD-YYYY in localStorage
    handleDateInput() {
      const [year, month, day] = this.expirationdateInput.split('-');
      this.expirationdate = `${month}-${day}-${year}`; // Convert to MM-DD-YYYY
      this.saveAndValidateForm();
    },

    // Convert the saved MM-DD-YYYY date to YYYY-MM-DD for the input field
    loadDateForInput() {
      if (this.expirationdate) {
        const [month, day, year] = this.expirationdate.split('-');
        this.expirationdateInput = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`; // Format as YYYY-MM-DD
      }
    },

    // Save and validate form data
    saveAndValidateForm() {
      this.saveDetails();
      this.validateForm();
    },

    // Validate form fields and trigger validation
    validateForm() {
      const isValid =
        this.dealtitle.trim().length > 0 &&
        this.expirationdate.trim().length > 0 &&
        this.quill.root.innerHTML.trim().length > 0 &&
        this.quillFinePrint.root.innerHTML.trim().length > 0;

      this.$emit('formValidation', isValid);
    },

    // Save form data to localStorage
    saveDetails() {
      const dealDetails = {
        dealtitle: this.dealtitle,
        expirationdate: this.expirationdate, // Save as MM-DD-YYYY
        couponcode: this.couponcode,
        dealdetails: this.quill.root.innerHTML,
        dealfineprint: this.quillFinePrint.root.innerHTML,
      };
      localStorage.setItem('editDetails', JSON.stringify(dealDetails));
    },

    // Load details from localStorage
    loadDetails() {
      const savedDetails = JSON.parse(localStorage.getItem('editDetails'));
      if (savedDetails) {
        this.dealtitle = savedDetails.dealtitle || '';
        this.expirationdate = savedDetails.expirationdate || ''; // Load MM-DD-YYYY format
        this.couponcode = savedDetails.couponcode || '';
        this.dealdetails = savedDetails.dealdetails || '';
        this.dealfineprint = savedDetails.dealfineprint || '';

        // Convert the MM-DD-YYYY date to YYYY-MM-DD for the input field
        this.loadDateForInput();

        // Set content in the Quill editors
        if (this.quill) {
          this.quill.root.innerHTML = this.dealdetails;
        }
        if (this.quillFinePrint) {
          this.quillFinePrint.root.innerHTML = this.dealfineprint;
        }
      }
    },

    // Generate a random coupon code
    generateCouponCode() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let couponCode = '';
      for (let i = 0; i < 12; i++) {
        if (i > 0 && i % 3 === 0) {
          couponCode += '-';
        }
        couponCode += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      this.couponcode = couponCode;
      this.saveAndValidateForm();
    },
  },

  mounted() {
    // Initialize Quill editor for Deal Details
    this.quill = new Quill(this.$refs.dealDetailsEditor, {
      theme: 'snow',
      placeholder: 'Describe the deal...',
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'code-block', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    // Initialize Quill editor for Deal Fine Print
    this.quillFinePrint = new Quill(this.$refs.dealFinePrintEditor, {
      theme: 'snow',
      placeholder: 'Add fine print...',
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'code-block', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    // Load details from localStorage if they exist
    this.loadDetails();

    // Set up event listeners for Quill editors
    this.quill.on('text-change', () => {
      this.dealdetails = this.quill.root.innerHTML;
      this.saveAndValidateForm();
    });

    this.quillFinePrint.on('text-change', () => {
      this.dealfineprint = this.quillFinePrint.root.innerHTML;
      this.saveAndValidateForm();
    });

    // Validate form on page load
    this.$emit(
      'formValidation',
      this.dealtitle && this.expirationdate && this.couponcode && this.dealdetails && this.dealfineprint
    );
  },

  created() {
    // Load details whenever the component is created (on page reload or when returning)
    this.loadDetails();
  },
};
</script>


<style scoped>
.step-details {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

#dealDetails,
#dealFinePrint {
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

#dealDetails:focus,
#dealFinePrint:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

.coupon-code-container {
  display: flex;
  gap: 10px;
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .form-group {
    margin-bottom: 15px;
  }

  input,
  select,
  textarea,
  #dealDetails,
  #dealFinePrint {
    padding: 8px;
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .form-group {
    margin-bottom: 10px;
  }

  input,
  select,
  textarea,
  #dealDetails,
  #dealFinePrint {
    padding: 6px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .form-group {
    margin-bottom: 8px;
  }

  input,
  select,
  textarea,
  #dealDetails,
  #dealFinePrint {
    padding: 5px;
    font-size: 13px;
  }
}
</style>
