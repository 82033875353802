<template>
  <div>
    <div class="hamburger-menu" @click="toggleMobileSidebar">
      <img src="https://img.icons8.com/material-outlined/24/000000/menu--v1.png" alt="Menu">
    </div>
    <div class="sidebar" :class="{ 'mobile-active': isMobileActive, 'mobile-collapsed': isMobileCollapsed }">
      <div class="sidebar-header">
        <img v-if="!isMobileCollapsed || isDesktop" src="@/assets/logo.png" alt="Stark Shopper" class="logo">
      </div>
      <!-- Add a scrollable container for the menu items -->
      <div class="sidebar-content">
        <ul class="sidebar-menu">
          <li v-for="item in filteredMenuItems" :key="item.name" class="menu-item">
            <router-link 
              v-if="!item.submenu" 
              :to="item.link" 
              @click="activateMenuItem(item)"
              :class="{'active': $route.path === item.link}"
            >
              <img :src="item.icon" alt="Icon" class="menu-icon">
              <span class="menu-text">{{ item.name }}</span>
            </router-link>
            <a v-else @click="toggleSubmenu(item)" :class="{'active': hasActiveSubItem(item)}">
              <img :src="item.icon" alt="Icon" class="menu-icon">
              <span class="menu-text">{{ item.name }}</span>
              <img 
                v-if="item.submenu"
                src="https://img.icons8.com/material-outlined/24/000000/expand-arrow--v1.png" 
                alt="Expand"
                class="submenu-indicator"
                :class="{'rotate': hasActiveSubItem(item)}"
              >
            </a>
            <ul v-if="item.submenu && (item.active || hasActiveSubItem(item))" class="submenu">
              <li v-for="subItem in item.submenu" :key="subItem.name">
                <router-link 
                  :to="subItem.link" 
                  @click="activateSubMenuItem(item, subItem)"
                  :class="{'active': $route.path === subItem.link}"
                >
                  {{ subItem.name }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="overlay" v-if="isMobileActive" @click="closeMobileSidebar"></div>
  </div>
</template>



<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'SideBar',
  setup() {
    const router = useRouter();
    const route = useRoute();

    const user = ref(JSON.parse(localStorage.getItem('user')));
    const isMobileActive = ref(false);
    const isMobileCollapsed = ref(true);
    const menuItems = ref([
      {
        name: 'Home',
        icon: 'https://img.icons8.com/material-outlined/24/000000/control-panel--v1.png',
        link: '/dashboard'
      },
      {
        name: 'Administration',
        icon: 'https://img.icons8.com/material-outlined/24/000000/lock--v1.png',
        submenu: [
          { name: 'Sales', link: '/dashboard/administration/sales', requiresAdmin: true },
          { name: 'Payouts', link: '/dashboard/administration/payouts', requiresAdmin: true },
          { name: 'Create Business', link: '/dashboard/administration/create-business', requiresAdmin: true },
          { name: 'Update User', link: '/dashboard/administration/update-user', requiresAdmin: true },
          { name: 'Update Business', link: '/dashboard/administration/update-business', requiresAdmin: true },
          { name: 'Email List', link: '/dashboard/administration/email-list', requiresAdmin: true },
        ],
        requiresAdmin: true
      },
      {
        name: 'Coupons',
        icon: 'https://img.icons8.com/material-outlined/24/000000/bill.png',
        submenu: [
          { name: 'View All Coupons', link: '/dashboard/view-coupons' },
          { name: 'Create Coupon', link: '/dashboard/create-coupon' },
          { name: 'Create Coupon for Business', link: '/dashboard/create-coupon-for-business', requiresAdmin: true },
          { name: 'Remove Coupon for Business', link: '/dashboard/remove-coupon-for-business', requiresAdmin: true }
        ]
      },
      {
        name: 'Gift Cards',
        icon: 'https://img.icons8.com/material-outlined/24/000000/bank-card-back-side.png',
        submenu: [
          { name: 'View All Gift Cards', link: '/dashboard/view-gift-cards' },
          { name: 'Create Gift Card', link: '/dashboard/create-gift-card', requiresAdmin: true },
          { name: 'Edit Gift Card', link: '/dashboard/edit-gift-card', requiresAdmin: true },
          { name: 'Remove Gift Cards', link: '/dashboard/remove-gift-card-for-business', requiresAdmin: true }
        ]
      },
      {
        name: 'Business Profile',
        icon: 'https://img.icons8.com/material-outlined/24/000000/user.png',
        link: '/dashboard/business-profile'
      },
      {
        name: 'Send Notifications',
        icon: 'https://img.icons8.com/material-outlined/24/000000/filled-appointment-reminders.png',
        link: '/dashboard/notification'
      },
      {
        name: 'Advertising',
        icon: 'https://img.icons8.com/material-outlined/24/000000/commercial--v1.png',
        submenu: [
          { name: 'View All Sponsored Items', link: '/dashboard/view-sponsored-items', requiresAdmin: true },
          { name: 'Create Sponsored Item', link: '/dashboard/create-sponsored-item', requiresAdmin: true },
          { name: 'Create Slider Advertisement', link: '/dashboard/create-slider-ad', requiresAdmin: true },
          { name: 'View Slider Advertisements', link: '/dashboard/view-slider-ads', requiresAdmin: true },
          { name: 'Create Trending', link: '/dashboard/create-trending', requiresAdmin: true},
        ],
        requiresAdmin: true
      },
      {
        name: 'Support',
        icon: 'https://img.icons8.com/material-outlined/24/000000/phone.png',
        link: '/support'
      },
      {
        name: 'Leave Dashboard',
        icon: 'https://img.icons8.com/material-outlined/24/000000/circled-left--v1.png',
        link: '/'
      }
    ]);

    const isDesktop = computed(() => window.innerWidth >= 1024);

    const filteredMenuItems = computed(() => {
      return menuItems.value.map(item => {
        if (item.submenu) {
          item.submenu = item.submenu.filter(subItem => {
            if (subItem.requiresAdmin) {
              return user.value && user.value.isAdmin;
            }
            return true;
          });
        }
        if (item.requiresAdmin) {
          return user.value && user.value.isAdmin ? item : undefined;
        }
        return item;
      }).filter(item => item !== undefined);
    });

    const hasActiveSubItem = (item) => {
      return item.submenu && item.submenu.some(subItem => route.path === subItem.link);
    };

    const activateMenuItem = (selectedItem) => {
      menuItems.value.forEach(item => {
        if (item === selectedItem) {
          item.active = true;
        } else {
          item.active = false;
        }
      });

      if (selectedItem.link) {
        router.push(selectedItem.link);
      }
    };

    const activateSubMenuItem = (parentItem, selectedSubItem) => {
      menuItems.value.forEach(item => {
        if (item !== parentItem) {
          item.active = false;
          if (item.submenu) {
            item.submenu.forEach(subItem => {
              subItem.active = false;
            });
          }
        }
      });

      parentItem.active = true;
      parentItem.submenu.forEach(subItem => {
        subItem.active = subItem === selectedSubItem;
      });

      router.push(selectedSubItem.link);
    };

    const toggleSubmenu = (selectedItem) => {
      if (!isDesktop.value) {
        isMobileActive.value = true;
        isMobileCollapsed.value = false;
      }

      selectedItem.active = !selectedItem.active;
    };

    const toggleMobileSidebar = () => {
      isMobileActive.value = !isMobileActive.value;
    };

    const closeMobileSidebar = () => {
      isMobileActive.value = false;
      isMobileCollapsed.value = true;
    };

    const handleResize = () => {
      isMobileCollapsed.value = window.innerWidth < 1024;
    };

    const initializeActiveStates = () => {
      const currentPath = route.path;
      menuItems.value.forEach(item => {
        item.active = item.link === currentPath || hasActiveSubItem(item);
        if (item.submenu) {
          item.submenu.forEach(subItem => {
            subItem.active = subItem.link === currentPath;
          });
        }
      });
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      handleResize();
      initializeActiveStates();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    watch(route, () => {
      initializeActiveStates();
    });

    return {
      user,
      isMobileActive,
      isMobileCollapsed,
      menuItems,
      isDesktop,
      filteredMenuItems,
      toggleSubmenu,
      activateMenuItem,
      activateSubMenuItem,
      toggleMobileSidebar,
      closeMobileSidebar,
      hasActiveSubItem,
    };
  }
};
</script>


<style scoped>
/* General Styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  padding: 20px 0;
  padding-left: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease, width 0.3s ease;
}

.sidebar-content {
  overflow-y: auto;
  flex-grow: 1; /* Allow the content to grow and fill the remaining space */
  padding-right: 10px;
  padding-bottom: 50px; /* Avoid scrollbar overlay on content */
}

/* Custom scrollbar styling */
.sidebar-content::-webkit-scrollbar {
  width: 8px;
}

.sidebar-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.sidebar-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar-header {
  padding: 20px;
  border-bottom: 1px solid #eaeaea;
  flex-shrink: 0; /* Prevents shrinking when sidebar height is reduced */
}

.sidebar-header h1 {
  color: #000;
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
}

.logo {
  max-width: 100%;
  height: auto;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.menu-item {
  margin-bottom: 10px;
}

.menu-item a, .menu-item router-link {
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: #000;
  transition: background-color 0.3s;
}

.menu-item a.active {
  background-color: #A3BF8F;
  color: white;
}

.menu-item a:hover {
  background-color: #6E9F49;
  cursor: pointer;
}

.menu-item img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.submenu-indicator {
  margin-left: auto;
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.submenu-indicator.rotate {
  transform: rotate(180deg);
}

.submenu {
  list-style: none;
  padding: 0;
}

.submenu a {
  color: #666;
  padding: 5px 20px;
  display: block;
}

.submenu a.active {
  background-color: #A3BF8F;
}

.submenu a:hover{
  background-color: #6E9F49;
  color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Mobile Styles */
.hamburger-menu {
  display: none;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1001;
  cursor: pointer;
}

.hamburger-menu img {
  width: 24px;
  height: 24px;
}

@media (max-width: 1024px) {
  .sidebar {
    transform: translateX(-300px);
  }

  .sidebar.mobile-active {
    transform: translateX(0);
  }

  .hamburger-menu {
    display: block;
  }

  .menu-item a span {
    display: none;
  }

  .submenu.show-submenu {
    display: block;
  }

  .sidebar.mobile-active .menu-item a span {
    display: inline;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 225px;
    padding-top: 75px;
  }

  .sidebar-header h1 {
    font-size: 18px;
  }

  .menu-item a span {
    display: none;
  }

  .sidebar.mobile-active .menu-item a span {
    display: inline;
  }

  .sidebar-content {
    padding-bottom: 100px; /* Avoid scrollbar overlay on content */
  }
}
</style>