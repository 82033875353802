<template>
  <div class="step-details">
    <h2>Step 1: Details</h2>
    <div class="form-group">
      <label for="dealTitle">Deal Title</label>
      <input type="text" id="dealTitle" v-model="dealTitle" @input="validateForm" required />
    </div>

    <!-- Expiration Date Dropdown -->
    <div class="form-group">
      <label for="expiration">Expiration Date</label>
      <select id="expiration" v-model="expiration" @change="handleExpirationChange" required>
        <option disabled value="">Choose one</option>
        <option value="5">5 days from today</option>
        <option value="10">10 days from today</option>
        <option value="15">15 days from today</option>
        <option value="30">30 days from today</option>
        <option value="exact">Exact Date</option>
      </select>
    </div>

    <!-- Calendar for Exact Date -->
    <div v-if="showCalendar" class="form-group">
      <label for="exactExpirationDate">Select Exact Expiration Date</label>
      <input type="date" id="exactExpirationDate" v-model="exactExpirationDate" @input="validateForm" />
    </div>

    <!-- Coupon Code Field with Generate Button -->
    <div class="form-group">
      <label for="couponCode">Coupon Code</label>
      <div class="coupon-code-container">
        <input type="text" id="couponCode" v-model="couponCode" maxlength="15" @input="validateForm" />
        <button type="button" @click="generateCouponCode">Generate</button>
      </div>
    </div>

    <div class="form-group">
      <label for="dealDetails">About Deal</label>
      <div id="dealDetails" ref="dealDetailsEditor"></div>
    </div>
    <div class="form-group">
      <label for="dealFinePrint">Fine Print</label>
      <div id="dealFinePrint" ref="dealFinePrintEditor"></div>
    </div>
  </div>
</template>


<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
  name: 'StepDetails',
  data() {
    return {
      dealTitle: '',
      expiration: '', // New field for expiration preset
      exactExpirationDate: '', // For holding the exact expiration date
      showCalendar: false, // Determines if the calendar is shown
      couponCode: '',
      dealDetails: '',
      dealFinePrint: '',
      maxPerMonth: 0,
      quill: null,
      quillFinePrint: null,
      defaultFinePrint: `
        This coupon is valid for use in a single transaction only. It is non-transferable and cannot be redeemed for cash or used to purchase gift cards. 
        This offer is not valid in conjunction with any other discounts, sales, special offers, or promotions, including storewide sales. 
        Only one coupon may be used per customer, per visit. The coupon must be presented at the time of purchase and cannot be applied to previous transactions. 
        This coupon applies only to in-stock items and cannot be used on back-ordered or pre-ordered items. 
        Additional restrictions may apply to certain product categories or services. 
        The merchant reserves the right to refuse, modify, or cancel the coupon at any time, and the coupon is void where prohibited by law. 
        This coupon has no monetary value and cannot be exchanged for cash or credit. Unauthorized reproduction, resale, or distribution of this coupon is prohibited.
      `,
    };
  },
  methods: {
    handleExpirationChange() {
      if (this.expiration === 'exact') {
        this.showCalendar = true;
      } else {
        this.showCalendar = false;
        this.exactExpirationDate = ''; // Reset exact date when not using it
      }
      this.validateForm();
    },
    validateForm() {
      const isValid =
        this.dealTitle.trim().length > 0 &&
        this.expiration.trim().length > 0 &&
        (this.expiration !== 'exact' || this.exactExpirationDate.trim().length > 0) &&
        this.couponCode.trim().length > 0 &&
        this.quill.root.innerHTML.trim().length > 0 &&
        this.quillFinePrint.root.innerHTML.trim().length > 0;

      this.$emit('formValidation', isValid);
    },
    validateAndSave() {
      this.dealDetails = this.quill.root.innerHTML;
      this.dealFinePrint = this.quillFinePrint.root.innerHTML;
      if (this.dealTitle && this.expiration && this.dealDetails && this.dealFinePrint && this.couponCode) {
        this.saveDetails();
        this.$emit('nextStep');
      }
    },
    saveDetails() {
      const dealDetails = {
        dealTitle: this.dealTitle,
        expiration: this.expiration === 'exact' ? this.exactExpirationDate : this.expiration,
        couponCode: this.couponCode,
        dealDetails: this.dealDetails,
        dealFinePrint: this.dealFinePrint,
        maxPerMonth: this.maxPerMonth || 0,
      };
      localStorage.setItem('dealDetails', JSON.stringify(dealDetails));
    },
    setDetails(details) {
      this.dealTitle = details.dealTitle;
      this.expiration = details.expiration;
      this.couponCode = details.couponCode || '';
      this.dealDetails = details.dealDetails;
      this.dealFinePrint = details.dealFinePrint || this.defaultFinePrint;
      this.quill.root.innerHTML = this.dealDetails;
      this.quillFinePrint.root.innerHTML = this.dealFinePrint;
      if (this.expiration === 'exact') {
        this.showCalendar = true;
        this.exactExpirationDate = details.exactExpirationDate;
      }
    },
    generateCouponCode() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let couponCode = '';
      for (let i = 0; i < 12; i++) {
        if (i > 0 && i % 3 === 0) {
          couponCode += '-';
        }
        couponCode += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      this.couponCode = couponCode;
      this.validateForm();
    },
  },
  mounted() {
    this.quill = new Quill(this.$refs.dealDetailsEditor, {
      theme: 'snow',
      placeholder: 'Describe the deal...',
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'code-block', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    this.quillFinePrint = new Quill(this.$refs.dealFinePrintEditor, {
      theme: 'snow',
      placeholder: 'Add fine print...',
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'code-block', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    const savedDetails = JSON.parse(localStorage.getItem('dealDetails'));
    if (savedDetails) {
      this.setDetails(savedDetails);
    } else {
      this.quillFinePrint.root.innerHTML = this.defaultFinePrint;
    }

    this.$emit('formValidation', this.dealTitle && this.expiration && this.dealDetails);

    this.quill.on('text-change', () => {
      this.dealDetails = this.quill.root.innerHTML;
      this.validateForm();
    });

    this.quillFinePrint.on('text-change', () => {
      this.dealFinePrint = this.quillFinePrint.root.innerHTML;
      this.validateForm();
    });
  },
};

</script>

<style scoped>
.step-details {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

#dealDetails,
#dealFinePrint {
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

#dealDetails:focus,
#dealFinePrint:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

/* Coupon Code Container */
.coupon-code-container {
  display: flex;
  gap: 10px;
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .form-group {
    margin-bottom: 15px;
  }

  input,
  select,
  textarea,
  #dealDetails,
  #dealFinePrint {
    padding: 8px;
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .form-group {
    margin-bottom: 10px;
  }

  input,
  select,
  textarea,
  #dealDetails,
  #dealFinePrint {
    padding: 6px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .form-group {
    margin-bottom: 8px;
  }

  input,
  select,
  textarea,
  #dealDetails,
  #dealFinePrint {
    padding: 5px;
    font-size: 13px;
  }
}
</style>
