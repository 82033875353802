<template>
  <div class="step-images">
    <h2>Step 3: Images</h2>

    <p v-if="generatingImage">Generating coupon image...</p>

    <!-- Main image display -->
    <div v-if="images.length > 0" class="main-image-container">
      <button class="main-arrow left" @click="prevImage" :disabled="selectedImageIndex === 0">&#9664;</button>
      <div class="main-image">
        <img :src="images[selectedImageIndex].url" alt="Main Image" />
      </div>
      <button class="main-arrow right" @click="nextImage" :disabled="selectedImageIndex === images.length - 1">&#9654;</button>
    </div>

    <!-- Thumbnails of uploaded images with navigation arrows -->
    <div class="thumbnail-slider-container" v-if="images.length > 0">
      <button class="thumbnail-arrow left" v-if="images.length > thumbnailsPerPage" @click="prevBatch" :disabled="currentBatch === 0">
        <span>&#9664;</span>
      </button>
      <div class="thumbnail-slider">
        <div class="thumbnail-container">
          <div class="thumbnail-item" v-for="(image, index) in visibleThumbnails" :key="index">
            <div class="image-preview" @click="selectImage(index + currentBatch * thumbnailsPerPage)">
              <img :src="image.url" :alt="'Image ' + (index + 1)" />
            </div>
            <button class="delete-button" @click="confirmDelete(index + currentBatch * thumbnailsPerPage)">X</button>
          </div>
        </div>
      </div>
      <button class="thumbnail-arrow right" v-if="images.length > thumbnailsPerPage" @click="nextBatch" :disabled="(currentBatch + 1) * thumbnailsPerPage >= images.length">
        <span>&#9654;</span>
      </button>
    </div>

    <!-- Upload more images box -->
    <div class="upload-more-box" @click="triggerMoreFileUpload" @drop.prevent="handleDrop" @dragover.prevent>
      <p>Upload more or drag here</p>
      <input type="file" @change="handleFileUpload" ref="moreFileInput" multiple hidden />
    </div>

    <!-- Hidden div for coupon preview for image generation -->
    <div ref="couponPreview" class="coupon-preview" style="display: none; width: 800px; height: 400px;">
      <div class="printable-coupon" style="width: 100%; height: 100%; background-color: #f9f9f9; padding: 20px; box-sizing: border-box; font-family: Arial, sans-serif; position: relative; border: 2px dashed #000;">
        <div class="coupon-header" style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <div class="logo-container" style="width: 200px; height: 100px; display: flex; justify-content: center; align-items: center;">
            <img :src="businessLogo" alt="Business Logo" style="max-width: 100%; max-height: 100%; object-fit: contain;" />
          </div>
          <div class="coupon-details" style="text-align: left;">
            <h2 style="margin: 0; font-size: 24px;">{{ editDetails.dealtitle }}</h2>
            <p style="margin: 5px 0; font-size: 18px;">Coupon Code: {{ Array.isArray(editDetails.couponcode) ? editDetails.couponcode[0] : editDetails.couponcode }}</p>
            <p style="margin: 5px 0 10px 0; font-size: 16px;">EXP Date: {{ formatExpirationDate(editDetails.expirationdate) }}</p>
            <img ref="barcodeImage" alt="Barcode" style="max-width: 100%; height: auto;" />
          </div>
        </div>
        <div class="coupon-content" style="margin-top: 20px; margin-bottom: 10px;">
          <h3 style="margin: 0 0 5px 0; font-size: 16px;">About this coupon:</h3>
          <div style="margin: 0; font-size: 14px; white-space: pre-wrap;">{{ stripHtml(editDetails.dealdetails) }}</div>
        </div>
        <div class="fine-print" style="margin-top: 10px; position: absolute; bottom: 20px; left: 20px; right: 20px;">
          <p style="margin: 0; font-size: 12px; font-weight: bold;">FINE PRINT:</p>
          <div style="margin: 0; font-size: 10px; white-space: pre-wrap;">{{ stripHtml(editDetails.dealfineprint) }}</div>
        </div>
      </div>
    </div>

    <!-- Delete confirmation dialog -->
    <div v-if="showDeleteConfirm" class="delete-confirmation">
      <p>Are you sure you want to delete this image?</p>
      <button @click="deleteImage">Yes</button>
      <button @click="cancelDelete">No</button>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import JsBarcode from 'jsbarcode';

export default {
  name: 'StepImages',
  data() {
    return {
      images: [],
      selectedImageIndex: 0,
      currentBatch: 0,
      showDeleteConfirm: false,
      deleteIndex: null,
      windowWidth: window.innerWidth,
      businessLogo: '',
      editDetails: {
        dealtitle: '',
        dealdetails: '',
        dealfineprint: '',
        couponcode: '',
        expiration: '',
        expirationdate: '',
      },
      generatingImage: false,
      lastGeneratedHash: '',
    };
  },
  computed: {
    thumbnailsPerPage() {
      return this.windowWidth <= 480 ? 3 : 6;
    },
    visibleThumbnails() {
      const start = this.currentBatch * this.thumbnailsPerPage;
      const end = start + this.thumbnailsPerPage;
      return this.images.slice(start, end);
    },
  },
  methods: {
    triggerMoreFileUpload() {
      this.$refs.moreFileInput.click();
    },
    handleFileUpload(event) {
      const files = event.target.files;
      this.processFiles(files);
    },
    handleDrop(event) {
      const files = event.dataTransfer.files;
      this.processFiles(files);
    },
    processFiles(files) {
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.compressImage(e.target.result, 0.8, (compressedImage) => {
            this.images.push({ url: compressedImage, name: file.name });
            if (this.images.length === 1) {
              this.selectedImageIndex = 0;
            }
            this.saveImagesToLocalStorage();
          });
        };
        reader.readAsDataURL(file);
      });
    },
    compressImage(dataURL, quality, callback) {
      const img = new Image();
      img.src = dataURL;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        callback(canvas.toDataURL('image/jpeg', quality));
      };
    },
    calculateExpiration() {
      if (this.editDetails.expirationdate) {
        // If expirationdate already exists, just format it
        this.editDetails.expirationdate = this.formatExpirationDate(this.editDetails.expirationdate);
      } else {
        const today = new Date();
        let expirationDate;

        if (this.editDetails.expiration && this.editDetails.expiration !== 'exact') {
          const days = parseInt(this.editDetails.expiration, 10);
          if (!isNaN(days)) {
            expirationDate = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
          }
        } else if (this.editDetails.exactexpirationdate) {
          expirationDate = new Date(this.editDetails.exactexpirationdate);
        }

        if (expirationDate && !isNaN(expirationDate.getTime())) {
          const month = (expirationDate.getMonth() + 1).toString().padStart(2, '0');
          const day = expirationDate.getDate().toString().padStart(2, '0');
          const year = expirationDate.getFullYear();
          this.editDetails.expirationdate = `${month}-${day}-${year}`;
        } else {
          console.warn('Invalid expiration date. Setting to empty string.');
          this.editDetails.expirationdate = '';
        }
      }
    },
    formatExpirationDate(dateString) {
      if (!dateString) return '';
      
      let parts;
      if (dateString.includes('-')) {
        parts = dateString.split('-');
      } else if (dateString.includes('/')) {
        parts = dateString.split('/');
      } else {
        return dateString; // Return as is if format is unrecognized
      }

      // Check if the first part is a 4-digit year
      if (parts[0].length === 4) {
        // YYYY-MM-DD format
        return `${parts[1]}-${parts[2]}-${parts[0]}`;
      } else {
        // MM-DD-YYYY format (already correct)
        return dateString;
      }
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    prevImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      }
    },
    nextImage() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
      }
    },
    prevBatch() {
      if (this.currentBatch > 0) {
        this.currentBatch--;
      }
    },
    nextBatch() {
      if ((this.currentBatch + 1) * this.thumbnailsPerPage < this.images.length) {
        this.currentBatch++;
      }
    },
    saveImagesToLocalStorage() {
      try {
        localStorage.setItem('uploadedImages', JSON.stringify(this.images));
      } catch (e) {
        console.error('Failed to save images to local storage:', e);
      }
    },
    loadLocalStorageData() {
      try {
        const savedDetails = JSON.parse(localStorage.getItem('editDetails'));
        const savedImages = JSON.parse(localStorage.getItem('uploadedImages'));
        const user = JSON.parse(localStorage.getItem('user'));
        const lastGeneratedHash = localStorage.getItem('lastGeneratedCouponHash');

        if (savedDetails) {
          this.editDetails = savedDetails;
          if (this.editDetails.expirationdate) {
            this.editDetails.expirationdate = this.formatExpirationDate(this.editDetails.expirationdate);
          }
        }
        if (savedImages && savedImages.length > 0) {
          this.images = [savedImages[0]];
        } else {
          this.images = [];
        }
        if (user && user.businessLogo) {
          this.businessLogo = user.businessLogo;
        }
        if (lastGeneratedHash) {
          this.lastGeneratedHash = lastGeneratedHash;
        }

        this.editDetails = this.editDetails || {};
        this.editDetails.couponcode = this.editDetails.couponcode || '';

        this.checkAndRegenerateCouponImage();
      } catch (e) {
        console.error('Failed to load data from local storage:', e);
      }
    },
    async generateCouponImage() {
      this.calculateExpiration();
      this.generatingImage = true;
      const couponElement = this.$refs.couponPreview;

      try {
        let barcodeDataUrl = '';
        const couponCode = this.editDetails.couponcode;
        
        // Extract the coupon code string from the array if necessary
        const codeString = Array.isArray(couponCode) ? couponCode[0] : couponCode;
        
        if (codeString && typeof codeString === 'string' && codeString.trim() !== '') {
          barcodeDataUrl = await this.generateBarcodeDataUrl(codeString.trim());
        } else {
          console.warn('Coupon code is empty or invalid. Skipping barcode generation.');
        }
        
        const barcodeImg = this.$refs.barcodeImage;
        barcodeImg.src = barcodeDataUrl;

        couponElement.style.display = 'block';
        await this.waitForImages(couponElement);

        const canvas = await html2canvas(couponElement, {
          width: 800,
          height: 400,
          scale: 2,
          backgroundColor: null,
          logging: false,
        });

        const imageDataUrl = canvas.toDataURL('image/png');

        if (imageDataUrl) {
          // Replace the entire images array with just the new coupon image
          this.images = [{ url: imageDataUrl, name: 'Generated Coupon Image' }];
          this.selectedImageIndex = 0;
          
          // Save the new hash
          this.lastGeneratedHash = this.generateCouponDataHash();
          localStorage.setItem('lastGeneratedCouponHash', this.lastGeneratedHash);
          
          this.saveImagesToLocalStorage();
        }
      } catch (error) {
        console.error('Error generating image:', error);
      } finally {
        this.generatingImage = false;
        couponElement.style.display = 'none';
      }
    },
    generateBarcodeDataUrl(code) {
      return new Promise((resolve) => {
        if (!code || typeof code !== 'string' || code.trim() === '') {
          resolve(''); // Return an empty string if the code is empty or invalid
          return;
        }
        const canvas = document.createElement('canvas');
        JsBarcode(canvas, code, {
          format: "CODE128",
          width: 1.5,
          height: 40,
          displayValue: true,
          fontSize: 10,
          margin: 5
        });
        resolve(canvas.toDataURL('image/png'));
      });
    },
    generateCouponDataHash() {
      const relevantData = {
        businessLogo: this.businessLogo,
        dealtitle: this.editDetails.dealtitle,
        dealdetails: this.editDetails.dealdetails,
        dealfineprint: this.editDetails.dealfineprint,
        couponcode: this.editDetails.couponcode,
        expirationdate: this.formatExpirationDate(this.editDetails.expirationdate)
      };
      return JSON.stringify(relevantData);
    },
    checkAndRegenerateCouponImage() {
      const currentHash = this.generateCouponDataHash();
      if (currentHash !== this.lastGeneratedHash) {
        this.generateCouponImage();
      }
    },
    stripHtml(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    waitForImages(element) {
      const images = element.getElementsByTagName('img');
      return Promise.all(Array.from(images).map(img => {
        if (img.complete) {
          return Promise.resolve();
        } else {
          return new Promise(resolve => {
            img.onload = resolve;
            img.onerror = resolve;
          });
        }
      }));
    },
    confirmDelete(index) {
      this.showDeleteConfirm = true;
      this.deleteIndex = index;
    },
    deleteImage() {
      if (this.deleteIndex !== null) {
        this.images.splice(this.deleteIndex, 1);
        this.saveImagesToLocalStorage();
        this.showDeleteConfirm = false;
        this.deleteIndex = null;
        if (this.selectedImageIndex >= this.images.length) {
          this.selectedImageIndex = this.images.length - 1;
        }
      }
    },
    cancelDelete() {
      this.showDeleteConfirm = false;
      this.deleteIndex = null;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    couponDetails: {
      deep: true,
      handler() {
        this.checkAndRegenerateCouponImage();
      }
    },
    businessLogo() {
      this.checkAndRegenerateCouponImage();
    }
  },
  mounted() {
    this.loadLocalStorageData();
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped>
.step-images {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-box {
  width: 800px;
  height: 300px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.upload-more-box {
  width: 800px;
  height: 100px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.upload-box p,
.upload-more-box p {
  margin: 0;
  text-align: center;
}

.main-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 825px;
  height: 400px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

.main-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.main-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumbnail-slider-container {
  display: flex;
  align-items: center;
  width: 730px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.thumbnail-slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100%);
}

.thumbnail-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.thumbnail-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-preview {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 5px;
  margin-right: 20px;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.delete-button {
  padding: 5px 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: darkred;
}

.main-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.main-arrow.left {
  left: 10px;
}

.main-arrow.right {
  right: 10px;
}

.thumbnail-arrow {
  width: 10px;
  height: 100px;
  background-color: #4C6B30;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: relative;
}

.thumbnail-arrow.left {
  margin-right: 5px;
}

.thumbnail-arrow.right {
  margin-left: 5px;
}

.main-arrow:disabled,
.thumbnail-arrow:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.delete-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.delete-confirmation p {
  margin-bottom: 10px;
}

.delete-confirmation button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #6E9F49;
}

@media (max-width: 1024px) {
  .upload-box,
  .upload-more-box {
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  .upload-box,
  .upload-more-box {
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  .upload-box,
  .upload-more-box {
    max-width: 100%;
    height: 300px;
  }

  .main-image-container{
    width: 100%;
  }

  .thumbnail-slider-container {
    width: 100%;
  }
}
</style>