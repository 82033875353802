<template>
  <div class="checkout-page">
    <h1>Checkout</h1>
    <div class="checkout-container">
      <div class="billing-details">
        <h2>Billing Details</h2>
        <form @input="saveBillingDetails">
          <div class="form-row">
            <div class="form-group">
              <label for="firstName">*First Name</label>
              <input type="text" id="firstName" v-model="billingDetails.firstName" required />
            </div>
            <div class="form-group">
              <label for="lastName">*Last Name</label>
              <input type="text" id="lastName" v-model="billingDetails.lastName" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="companyName">Company Name</label>
              <input type="text" id="companyName" v-model="billingDetails.companyName" />
            </div>
            <div class="form-group">
              <label for="address">*Address</label>
              <input type="text" id="address" v-model="billingDetails.address" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="city">*Town/City</label>
              <input type="text" id="city" v-model="billingDetails.city" required />
            </div>
            <div class="form-group">
              <label for="zipCode">*Zip Code</label>
              <input type="text" id="zipCode" v-model="billingDetails.zipCode" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="country">Country</label>
              <select id="country" v-model="billingDetails.country" required>
                <option v-for="country in countries" :key="country.code" :value="country.code">
                  {{ country.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="email">*Email Address</label>
              <input type="email" id="email" v-model="billingDetails.email" required />
            </div>
            <div class="form-group">
              <label for="phone">*Phone</label>
              <input type="tel" id="phone" v-model="billingDetails.phone" required />
            </div>
          </div>
        </form>
      </div>
      <div class="order-summary">
        <h2>Your Order</h2>
        <div v-for="(item, index) in checkoutCartItems" :key="index" class="order-item">
          <div class="item-name">{{ item.dealTitle }}</div>
          <div class="item-price">${{ calculateDiscountedPrice(item.originalPrice, item.discount) }}</div>
        </div>
        <div class="subtotal-cost">
          <span>Subtotal</span>
          <span>${{ subtotalCost }}</span>
        </div>
        <div class="tax-cost">
          <span>Tax (6.5%)</span>
          <span>${{ taxCost }}</span>
        </div>
        <div class="total-cost">
          <span>Total Cost</span>
          <span>${{ totalCost }}</span>
        </div>
        <h2>Payment Information</h2>
        <form @submit.prevent="handleCheckout">
          <label for="card-element">Credit or debit card</label>
          <div id="card-element">
            <!-- A Stripe Element will be inserted here. -->
          </div>
          <!-- Used to display form errors. -->
          <div id="card-errors" role="alert"></div>
          <button type="submit" class="place-order-button" :disabled="isProcessing">Place Order</button>
          <div v-if="isProcessing" class="processing-spinner">
            <div class="spinner"></div>
            <span>Processing...</span>
          </div>
        </form>
        <div v-if="transactionMessage" class="transaction-message">{{ transactionMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

export default {
  name: 'CheckoutPage',
  data() {
    return {
      stripe: null,
      elements: null,
      card: null,
      transactionMessage: '',
      isProcessing: false,
      billingDetails: {
        firstName: '',
        lastName: '',
        companyName: '',
        address: '',
        city: '',
        zipCode: '',
        country: 'US', // Default to United States
        email: '',
        phone: ''
      },
      checkoutCartItems: JSON.parse(localStorage.getItem('checkoutCartItems')) || [],
      countries: [] // Initialize as empty array
    };
  },
  computed: {
    subtotalCost() {
      let subtotal = this.checkoutCartItems.reduce((total, item) => {
        const discountedPrice = parseFloat(this.calculateDiscountedPrice(item.originalPrice, item.discount));
        return total + (isNaN(discountedPrice) ? 0 : discountedPrice) * (item.quantity || 1);
      }, 0);
      return subtotal.toFixed(2);
    },
    taxCost() {
      let tax = parseFloat(this.subtotalCost) * 0.065;
      return tax.toFixed(2);
    },
    totalCost() {
      let total = parseFloat(this.subtotalCost) + parseFloat(this.taxCost);
      return total.toFixed(2);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.updateCartItems();
    });
  },
  mounted() {
    this.initializeStripe();
    this.loadBillingDetails();
    this.fetchCountries(); // Fetch countries when component is mounted
    window.addEventListener('cart-updated', this.updateCartItems);
  },
  beforeUnmount() {
    window.removeEventListener('cart-updated', this.updateCartItems);
  },
  methods: {
    async initializeStripe() {
      this.stripe = await loadStripe('pk_test_51HVf6pEJDnrWZ4dJfAF33kjVRek3e066N5uQjkVdFHqeFvWe6AQgui21oRY00QBWgtVTkoOb6L0nD3BbTcGVYnRU00wYjEIyX4');
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card');
      this.card.mount('#card-element');
    },
    async fetchCountries() {
      try {
        const response = await axios.get('https://restcountries.com/v2/all');
        this.countries = response.data.map(country => ({
          code: country.alpha2Code,
          name: country.name
        }));
        this.billingDetails.country = 'US'; // Set default country to United States
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    },
    calculateDiscountedPrice(originalPrice, discount) {
      if (isNaN(originalPrice) || isNaN(discount)) {
        return 0;
      }
      return (originalPrice * (1 - discount / 100)).toFixed(2);
    },
    saveBillingDetails() {
      localStorage.setItem('billingDetails', JSON.stringify(this.billingDetails));
    },
    loadBillingDetails() {
      try {
        const savedDetails = localStorage.getItem('billingDetails');
        if (savedDetails) {
          this.billingDetails = JSON.parse(savedDetails);
        } else {
          console.warn('No billing details found in localStorage');
          this.billingDetails = {
            firstName: '',
            lastName: '',
            companyName: '',
            address: '',
            city: '',
            zipCode: '',
            country: 'US',
            email: '',
            phone: ''
          };
        }
      } catch (error) {
        console.error('Error parsing billing details:', error);
      }
    },
    updateCartItems(event) {
      if (event && event.detail) {
        this.checkoutCartItems = event.detail;
      } else {
        this.checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
      }
    },
    async handleCheckout() {
      this.isProcessing = true;
      try {
        const userId = JSON.parse(localStorage.getItem('user')).id;
        
        // Calculate total amount for all items
        const totalAmount = this.checkoutCartItems.reduce((total, item) => {
          const discountedPrice = parseFloat(this.calculateDiscountedPrice(item.originalPrice, item.discount));
          return total + (isNaN(discountedPrice) ? 0 : discountedPrice) * (item.quantity || 1);
        }, 0);

        const tax = totalAmount * 0.065;
        const grandTotal = totalAmount + tax;

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/payment/create-payment-intent`, {
          amount: Math.round(grandTotal * 100), // Stripe expects the amount in cents
          currency: 'usd',
          items: this.checkoutCartItems
        });

        const result = await this.stripe.confirmCardPayment(response.data.clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: `${this.billingDetails.firstName} ${this.billingDetails.lastName}`,
              address: {
                line1: this.billingDetails.address,
                city: this.billingDetails.city,
                postal_code: this.billingDetails.zipCode,
                country: this.billingDetails.country,
              },
              email: this.billingDetails.email,
              phone: this.billingDetails.phone,
            },
          },
        });

        if (result.error) {
          this.transactionMessage = result.error.message;
          this.isProcessing = false;
          return;
        } else {
          const items = this.checkoutCartItems.map(item => ({
            giftCardId: item.giftCardId,
            quantity: item.quantity || 1,
            dealTitle: item.dealTitle,
            discount: item.discount,
            originalPrice: item.originalPrice,
            businessId: item.businessId
          }));

          const orderConfirmation = await axios.post(`${process.env.VUE_APP_API_URL}/api/payment/confirm-order`, {
            userId,
            items,
            totalCost: grandTotal.toFixed(2),
            paymentIntentId: result.paymentIntent.id,
            email: this.billingDetails.email,
          });

          if (orderConfirmation.data.emailSent) {
            console.log('Order confirmation email sent successfully');
          }

          this.$router.push({
            name: 'OrderConfirmation',
            params: {
              userId: userId,
              orderId: orderConfirmation.data.order.orderId,
              orderData: JSON.stringify(orderConfirmation.data.order)
            }
          }).then(() => {
            // Remove all items from the cart
            this.checkoutCartItems.forEach(item => {
              removeFromCheckoutCart(item.giftCardId);
            });
            window.scrollTo(0, 0);
          });
        }

        const removeFromCheckoutCart = (giftCardId) => {
          return new Promise((resolve, reject) => {
            const user = JSON.parse(localStorage.getItem('user'));
            
            // If user is not logged in, remove the item from localStorage directly
            if (!user || !user.id) {
              let checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
              checkoutCartItems = checkoutCartItems.filter(item => item.giftCardId !== giftCardId);

              const event = new CustomEvent('update-checkout-cart', { detail: checkoutCartItems });
              window.dispatchEvent(event);
              localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
              resolve();
              return;
            }

            // If user is logged in, send a request to the API to remove the item
            const token = localStorage.getItem('token');
            axios.put(
              `${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-cart/${user.id}`,
              { giftCardId },
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                }
              }
            )
            .then(() => {
              let checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
              checkoutCartItems = checkoutCartItems.filter(item => item.giftCardId !== giftCardId);

              const event = new CustomEvent('update-checkout-cart', { detail: checkoutCartItems });
              window.dispatchEvent(event);
              localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
              resolve();
            })
            .catch((error) => {
              console.error('Error removing from checkout cart:', error);
              reject(error);
            });
          });
        };

        // Clear cart items from local storage
        localStorage.setItem('checkoutCartItems', '[]');
      } catch (error) {
        this.transactionMessage = 'An error occurred during checkout. Please try again.';
        console.error('Error during checkout:', error);
      } finally {
        this.isProcessing = false;
      }
    }
  }
};
</script>

<style scoped>
.checkout-page {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  margin-bottom: 50px;
}

h1 {
  text-align: center;
  margin-bottom: 40px;
}

.checkout-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.order-summary, .billing-details {
  width: 48%;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.billing-details h2, .order-summary h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input, .form-group select {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.form-row .form-group {
  flex: 1;
}

.place-order-button {
  width: 100%;
  padding: 15px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.subtotal-cost, .tax-cost, .total-cost {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 10px 0;
}

.total-cost {
  border-top: 2px solid #333;
  font-size: 1.2em;
  margin-top: 20px;
}

#card-element {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.transaction-message {
  margin-top: 30px;
  color: green;
  font-size: 1.2em;
  font-weight: bold;
}

.processing-spinner {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.processing-spinner .spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #4C6B30;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .checkout-container {
    flex-direction: column;
    align-items: center;
  }

  .order-summary, .billing-details {
    width: 100%;
    max-width: 600px;
  }

  .form-row {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .checkout-page {
    max-width: 100%;
    padding: 0 20px;
  }

  .checkout-container {
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .form-group input, .form-group select, #card-element {
    padding: 8px;
  }

  .place-order-button {
    padding: 10px;
    font-size: 16px;
  }
}
</style>
