<template>
  <div class="step-preview">
    <h2 class="deal-title">{{ couponDetails.dealTitle }}</h2>

    <div class="content-container">
      <!-- Left Side -->
      <div class="left-side">
        <!-- Image Viewer -->
        <div class="image-viewer">
          <button class="main-arrow left" v-if="images.length > 0" @click="prevImage" :disabled="selectedImageIndex === 0">&#9664;</button>
          <div class="main-image">
            <img v-if="images.length > 0" :src="images[selectedImageIndex].url" alt="Main Image" />
            <img v-else src="@/assets/default-image.png" alt="Default Image" />
          </div>
          <button class="main-arrow right" v-if="images.length > 0" @click="nextImage" :disabled="selectedImageIndex === images.length - 1">&#9654;</button>
        </div>

        <!-- Thumbnails -->
        <div class="thumbnail-slider-container" v-if="images.length > 0">
          <button class="thumbnail-arrow left" v-if="images.length > thumbnailsPerPage" @click="prevBatch" :disabled="currentBatch === 0">
            <span>&#9664;</span>
          </button>
          <div class="thumbnail-slider">
            <div class="thumbnail-container">
              <div class="image-preview" v-for="(image, index) in visibleThumbnails" :key="index" @click="selectImage(index + currentBatch * thumbnailsPerPage)">
                <img :src="image.url" :alt="'Image ' + (index + 1)" />
              </div>
            </div>
          </div>
          <button class="thumbnail-arrow right" v-if="images.length > thumbnailsPerPage" @click="nextBatch" :disabled="(currentBatch + 1) * thumbnailsPerPage >= images.length">
            <span>&#9654;</span>
          </button>
        </div>

        <!-- About Deal -->
        <div class="about-deal" v-if="couponDetails.dealDetails">
          <h2>About this Coupon</h2>
          <div v-html="couponDetails.dealDetails"></div>
        </div>

        <!-- Fine Print -->
        <div class="fine-print" v-if="couponDetails.dealFinePrint">
          <h2>Fine Print</h2>
          <div v-html="couponDetails.dealFinePrint"></div>
        </div>
      </div>

      <!-- Right Side -->
      <div class="right-side">
        <!-- Options (Radio buttons) -->
        <div class="options-list" v-if="couponDetails.couponCode">
          <ul>
            <li class="option-item">
              <div class="option-content">
                <input type="radio" id="coupon-option" name="coupon" checked />
                <label for="coupon-option" class="option-label">
                  <div class="option-header">
                    <strong>{{ couponDetails.dealTitle }}</strong>
                  </div>
                  
                  <!-- Expiration Date -->
                  <p class="expiration-date" v-if="couponDetails.expirationDate">
                    Expires on: {{ couponDetails.expirationDate }}
                  </p>

                  <div class="coupon-code-container">
                    <div class="coupon-code-flex">
                      <div class="coupon-code-box">{{ couponDetails.couponCode }}</div>
                      <div class="copy-button">Copy</div>
                    </div>
                  </div>
                </label>
              </div>
              <hr class="separator" />
            </li>
          </ul>
        </div>

        <div class="button-container">
          <button class="shopping-bag">Add to Coupon Bag</button>
          <button class="print">Print</button>
          <button class="subscribe-button">Become a Fan</button>
        </div>
        
        <!-- Share Section -->
        <div class="share-section">
          <h3>Share This Deal</h3>
          <div class="social-icons">
            <a href="#" class="social-icon"><i class="fas fa-envelope"></i></a>
            <a href="#" class="social-icon"><i class="fab fa-facebook"></i></a>
            <a href="#" class="social-icon"><i class="fab fa-twitter"></i></a>
            <a href="#" class="social-icon"><i class="fab fa-pinterest"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CouponPreview',
  data() {
    return {
      couponDetails: {
        dealTitle: '',
        dealDetails: '',
        dealFinePrint: '',
        couponCode: '',
        expiration: '',  // Store preset expiration (like 5, 10, 15, 30 days or 'exact')
        exactExpirationDate: '', // Store exact expiration date if selected
        expirationDate: '', // This will store the calculated expiration date in MM-DD-YYYY format
      },
      images: [],
      selectedImageIndex: 0,
      currentBatch: 0,
      thumbnailsPerPage: 6,
    };
  },
  methods: {
    calculateExpirationDate() {
      const today = new Date();
      let expirationDate;

      if (this.couponDetails.expiration !== 'exact') {
        // If preset (5, 10, 15, 30 days)
        const daysToAdd = parseInt(this.couponDetails.expiration, 10);
        expirationDate = new Date(today.setDate(today.getDate() + daysToAdd));
      } else {
        // If exact date selected by user
        expirationDate = new Date(this.couponDetails.exactExpirationDate);
      }

      // Format the expiration date as MM-DD-YYYY
      const formattedDate = `${(expirationDate.getMonth() + 1).toString().padStart(2, '0')}-${expirationDate.getDate().toString().padStart(2, '0')}-${expirationDate.getFullYear()}`;

      // Save the formatted expiration date
      this.couponDetails.expirationDate = formattedDate;

      // Update localStorage with the expiration date
      const dealDetails = JSON.parse(localStorage.getItem('dealDetails')) || {};
      dealDetails.expirationDate = formattedDate;
      localStorage.setItem('dealDetails', JSON.stringify(dealDetails));
    },
    loadLocalStorageData() {
      const savedDetails = JSON.parse(localStorage.getItem('dealDetails'));
      if (savedDetails) {
        this.couponDetails = savedDetails;

        // Calculate the expiration date if not already calculated
        if (!this.couponDetails.expirationDate) {
          this.calculateExpirationDate();
        }
      }

      const savedImages = JSON.parse(localStorage.getItem('uploadedImages'));
      if (savedImages) {
        this.images = savedImages;
      }
    },
    prevImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      }
    },
    nextImage() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
      }
    },
    prevBatch() {
      if (this.currentBatch > 0) {
        this.currentBatch--;
      }
    },
    nextBatch() {
      if ((this.currentBatch + 1) * this.thumbnailsPerPage < this.images.length) {
        this.currentBatch++;
      }
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
  },
  mounted() {
    this.loadLocalStorageData();
  },
};
</script>

<style scoped>
.step-preview {
  width: 97%;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  box-sizing: border-box;
}

.content-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.left-side,
.right-side {
  width: 44%;
  box-sizing: border-box;
}

.left-side {
  margin-left: 5%;
}

.right-side {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  align-self: flex-start;
}

.deal-title {
  text-align: left;
  width: 100%;
  margin-left: 5%;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.image-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.expiration-date {
  font-size: 0.9em;
  color: #777;
  margin-top: 5px;
  margin-bottom: 10px;
}

.main-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.main-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.main-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.main-arrow.left {
  left: 10px;
}

.main-arrow.right {
  right: 10px;
}

.thumbnail-slider-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.thumbnail-slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100% - 30px);
}

.thumbnail-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.image-preview {
  width: 109px;
  height: 109px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumbnail-arrow {
  width: 10px;
  height: 110px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: relative;
}

.thumbnail-arrow.left {
  margin-right: 5px;
}

.thumbnail-arrow.right {
  margin-left: 0px;
}

.about-deal,
.fine-print {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
}

.options-list {
  width: 100%;
  margin-bottom: 20px;
}

.option-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.option-content {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.option-label {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.option-header {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.option-details {
  margin-bottom: 8px;
}

.option-detail {
  font-size: 16px;
  color: #333;
}

.coupon-code-container {
  margin: 10px 0;
  text-align: left;
}

.coupon-code-flex {
  display: flex;
  align-items: center;
}

.coupon-code-box {
  display: inline-block;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  border: 1px dashed #ccc;
  padding: 5px 10px;
  background-color: #fff;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy-button {
  display: inline-block;
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #4C6B30;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
}

.separator {
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.button-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 40px;
}

.shopping-bag,
.print,
.subscribe-button {
  width: 55%;
  padding: 7px;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.shopping-bag {
  background-color: #4C6B30;
  color: white;
}

.print {
  background-color: #6E9F49;
  color: white;
}

.subscribe-button {
  background-color: #8DB36D;
  color: white;
}

.share-section {
  margin-top: 20px;
  text-align: left;
  margin-left: 40px;
}

.share-section h3 {
  margin: 0 0 10px;
  font-size: 16px;
  color: #333;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icon {
  font-size: 24px;
  color: #4C6B30;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .content-container {
    flex-direction: column;
    align-items: center;
  }

  .left-side,
  .right-side {
    width: 100%;
    margin: 0;
  }

  .right-side {
    position: static;
    margin-top: 20px;
  }

  .button-container,
  .share-section {
    margin-left: 0;
    align-items: center;
  }

  .shopping-bag,
  .print,
  .subscribe-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .step-preview {
    padding: 0 20px;
  }

  .deal-title {
    margin-left: 0;
    text-align: center;
  }

  .image-viewer {
    height: 300px;
  }

  .thumbnail-slider-container {
    max-width: 100%;
    overflow-x: auto;
  }

  .button-container,
  .share-section {
    margin-left: 0;
    align-items: center;
    width: 100%;
  }

  .shopping-bag,
  .print,
  .subscribe-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .step-preview {
    padding: 0 10px;
  }

  .image-viewer {
    height: 250px;
  }

  .thumbnail-slider-container {
    display: none;
  }

  .option-header {
    font-size: 16px;
  }

  .option-detail {
    font-size: 14px;
  }

  .coupon-code-flex {
    flex-direction: column;
    align-items: flex-start;
  }

  .copy-button {
    margin-left: 0;
    margin-top: 5px;
  }

  .button-container {
    margin-top: 10px;
  }

  .shopping-bag,
  .print,
  .subscribe-button {
    font-size: 16px;
  }

  .share-section h3 {
    font-size: 14px;
  }

  .social-icon {
    font-size: 20px;
  }
}
</style>
